<script lang="ts" setup>
import html2canvas from "html2canvas";

const emit = defineEmits(["close", "screenshotCaptured"]);

const mouseMoved = ref(false);
const dragging = ref(false);
const initialClick = { x: 0, y: 0 };

const onMouseDown = (event: MouseEvent) => {
  mouseMoved.value = false;

  if (isInsideSelection(event)) {
    // Start dragging
    dragging.value = true;
    initialClick.x = event.clientX - selection.startX;
    initialClick.y = event.clientY - selection.startY;
  } else {
    // Start a new selection
    selection.capturing = true;
    initialClick.x = event.clientX;
    initialClick.y = event.clientY;
  }
};

const onMouseMove = (event: MouseEvent) => {
  mouseMoved.value = true; // Mouse is moved, so it's not a simple click

  if (dragging.value) {
    // Update the position of the selection
    selection.startX = event.clientX - initialClick.x;
    selection.startY = event.clientY - initialClick.y;
  } else if (selection.capturing) {
    // Update the size of the selection
    selection.startX = initialClick.x;
    selection.startY = initialClick.y;
    selection.width = event.clientX - selection.startX;
    selection.height = event.clientY - selection.startY;
  }
};

const onMouseUp = (event: MouseEvent) => {
  if (!mouseMoved.value && !isInsideSelection(event)) {
    // If the mouse hasn't moved and the click is outside the selection, reset
    resetSelection();
  }

  selection.capturing = false;
  dragging.value = false;
};

const resetSelection = () => {
  selection.startX = 0;
  selection.startY = 0;
  selection.width = 0;
  selection.height = 0;
};

const isInsideSelection = (event: MouseEvent) => {
  const { clientX, clientY } = event;
  const endX = selection.startX + selection.width;
  const endY = selection.startY + selection.height;

  const minX = Math.min(selection.startX, endX);
  const maxX = Math.max(selection.startX, endX);
  const minY = Math.min(selection.startY, endY);
  const maxY = Math.max(selection.startY, endY);

  return (
    clientX >= minX && clientX <= maxX && clientY >= minY && clientY <= maxY
  );
};

const selection = reactive({
  startX: 0,
  startY: 0,
  width: 0,
  height: 0,
  capturing: false,
});

const overlayStyle = computed(() => {
  return {
    "clip-path": `polygon(
      0 0,
      0 100%,
      ${selection.startX}px 100%,
      ${selection.startX}px ${selection.startY}px,
      ${selection.startX + selection.width}px ${selection.startY}px,
      ${selection.startX + selection.width}px ${
      selection.startY + selection.height
    }px,
      ${selection.startX}px ${selection.startY + selection.height}px,
      ${selection.startX}px 100%,
      100% 100%,
      100% 0
    )`,
  };
});

const isCapturing = ref(false);
const showOverlay = ref(true);

const captureSelectedArea = async () => {
  isCapturing.value = true;

  try {
    showOverlay.value = false;

    await nextTick();

    const canvas = await html2canvas(document.body);

    showOverlay.value = false;

    await nextTick();

    const context = canvas.getContext("2d");

    // Calculate the dimensions and position of the selected area
    // Existing selection logic

    const scale = window.devicePixelRatio;

    // Adjusted calculations
    const x =
      Math.min(selection.startX, selection.startX + selection.width) * scale;
    const y =
      Math.min(selection.startY, selection.startY + selection.height) * scale;
    const width = Math.abs(selection.width) * scale;
    const height = Math.abs(selection.height) * scale;

    // Create a new canvas to place the cropped image
    const croppedCanvas = document.createElement("canvas");
    croppedCanvas.width = width;
    croppedCanvas.height = height;
    const croppedCtx = croppedCanvas.getContext("2d");

    if (!croppedCtx) {
      throw new Error("Could not get cropped context");
    }

    // Draw the cropped area onto the new canvas
    croppedCtx.drawImage(canvas, x, y, width, height, 0, 0, width, height);

    // Convert the canvas to a data URL (base64 encoded image)
    const screenshotData = croppedCanvas.toDataURL();

    // Emit the screenshot data
    emit("screenshotCaptured", screenshotData);
  } catch (error) {
    console.error("Error capturing screenshot:", error);
    isCapturing.value = false;
    // Handle errors appropriately
  }
};

const showControls = computed(
  () => selection.width != 0 && selection.height != 0 && !selection.capturing
);
const controlsStyle = computed(() => {
  // Calculate the actual start and end points
  const actualStartX = Math.min(
    selection.startX,
    selection.startX + selection.width
  );
  const actualStartY = Math.min(
    selection.startY,
    selection.startY + selection.height
  );
  const actualEndY = Math.max(
    selection.startY - 50,
    selection.startY + selection.height - 50
  );

  // Calculate the position of the control div
  const top = `${actualEndY}px`;
  const left = `${actualStartX + Math.abs(selection.width) / 2}px`;

  return {
    top: top,
    left: left,
    transform: "translateX(-50%)", // Keeps the div horizontally centered
  };
});

const closeCapture = () => {
  emit("close");
};

const onCancelSelection = () => {
  resetSelection();
};
</script>

<template>
  <div
    class="h-full w-full relative z-30"
    @mousedown="onMouseDown"
    @mouseup="onMouseUp"
    @mousemove="onMouseMove"
  >
    <div
      v-if-show="showOverlay"
      class="fixed top-0 left-0 w-screen h-screen pointer-events-none z-40"
      :class="{
        ' bg-black bg-opacity-50': showOverlay,
        'bg-transparent': !showOverlay,
      }"
      :style="overlayStyle"
    ></div>
    <div
      v-if="showControls == false && selection.capturing != true"
      class="absolute top-[50%] left-[50%] -translate-x-[50%] -translate-y-[50%] text-center z-50"
    >
      <p class="text-primary-on text-h3">
        Click and drag to select an area of the screen to capture.
      </p>
      <BaseButton
        @mousedown.stop.prevent
        @click="closeCapture"
        color="danger"
        class="mt-4 w-[150px] mx-auto"
      >
        cancel
      </BaseButton>
    </div>
    <div
      v-if="showControls && showOverlay"
      :style="controlsStyle"
      class="absolute flex flex-row items-center z-50"
    >
      <BaseFab
        @mousedown.stop.prevent
        @click.stop.prevent="captureSelectedArea"
        :show-spinner="isCapturing"
        v-tooltip="'Capture Screenshot'"
        class="mr-4"
      >
        <icon name="mdi:camera" class="" size="18" />
      </BaseFab>
      <BaseFab
        @mousedown.stop.prevent
        @click="onCancelSelection"
        color="danger"
        v-tooltip="'Cancel'"
      >
        <icon name="material-symbols:cancel-outline" size="18" />
      </BaseFab>

      <!-- Other buttons as needed -->
    </div>
  </div>
</template>

<style scoped></style>
