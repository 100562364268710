<script lang="ts" setup></script>

<template>
  <NuxtLink to="/articles" target="_blank" v-tooltip="'View Help Articles'">
    <BaseTextButton >
      <icon name="material-symbols:help" size="25"/>
    </BaseTextButton>
  </NuxtLink>
</template>

<style scoped></style>
